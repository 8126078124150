import React from "react";
import loadable from "@loadable/component";
import awsIcon from "@images/aws-logo.svg";
import nodeIcon from "@images/node-logo.svg";
import reactIcon from "@images/react-logo.svg";
import nestLogo from "@images/nest-logo.svg";
import { TechnologySectionStyle } from "../../../Styles/TechnologySectionStyle";

const TechnologyListSection = loadable(() =>
    import("@common/CommonSections/TechnologySection/TechnologySection")
);

const technologies = [
    {
        technologyIcon: nodeIcon,
        technologyIconAlt: "node logo",
    },
    {
        technologyIcon: reactIcon,
        technologyIconAlt: "react logo",
    },
    {
        technologyIcon: nestLogo,
        technologyIconAlt: "nest logo",
    },
    {
        technologyIcon: awsIcon,
        technologyIconAlt: "aws logo",
    },
];

const iconsSize = [
    {
        desktopSize: [55, 185],
        tabletSize: [41, 138],
        mobileSize: [27, 91],
    },
    {
        desktopSize: [64, 196],
        tabletSize: [48, 147],
        mobileSize: [32, 98],
    },
    {
        desktopSize: [64, 179],
        tabletSize: [45, 126],
        mobileSize: [31, 87],
    },
    {
        desktopSize: [64, 106],
        tabletSize: [48, 80],
        mobileSize: [32, 53],
    },
];

const TechnologySection = () => (
    <>
        <TechnologySectionStyle iconsSize={iconsSize} />
        <div className="technology-section__conatiner technology-section__conatiner-web">
            <TechnologyListSection technologies={technologies} />
        </div>
    </>
);

export default TechnologySection;
