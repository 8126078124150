import React from "react";
import { ReactSVG } from "react-svg";
import { LayoutWrapper } from "../../../../styles/CommonStyles";
import { TECHNOLOGIES } from "../../constants";
import {
  StyledTechnologiesTitle,
  StyledTechnologySectionContainer,
} from "./styled";

const TechnologySection = () => {
  return (
    <LayoutWrapper>
      <StyledTechnologiesTitle>Technologies</StyledTechnologiesTitle>
      <StyledTechnologySectionContainer>
        {TECHNOLOGIES.map(({ technologyIcon, technologyIconAlt }) => (
          <ReactSVG
            key={technologyIconAlt}
            src={technologyIcon}
          />
        ))}
      </StyledTechnologySectionContainer>
    </LayoutWrapper>
  );
};

export default TechnologySection;
