import styled from "styled-components"
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants"

export const StyledTechnologiesTitle = styled.h1`
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.8px;
  margin-top: 2.5rem;

  @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.64px;
  }

  @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.48px;
  }
`;

export const StyledTechnologySectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  column-gap: 2.5rem;
  row-gap: 56px;

  @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
    grid-template-columns: repeat(3, 1fr);
    padding: 20px;
    & svg {
      width: 141px;
      height: 48px;
    }
  }

  @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
    & svg {
      width: 99px;
      height: 32px;
    }
  }
`;
