import { createGlobalStyle } from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

const generateItemsStyle = sizes => {
    let styles = "";

    sizes.forEach((size, index) => {
        styles += `
            &:nth-child(${++index}) {
                .technologies-section__list-item-icon {
                    svg {
                        height: ${size.desktopSize[0]}px;
                        width: ${size.desktopSize[1]}px;
    
                        @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
                            height: ${size.tabletSize[0]}px;
                            width: ${size.tabletSize[1]}px;
                        }
    
                        @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
                            height: ${size.mobileSize[0]}px;
                            width: ${size.mobileSize[1]}px;
                        } 
                    }
                }
            }
        `;
    });

    return styles;
};

export const TechnologySectionStyle = createGlobalStyle`
    .technology-section__conatiner {
        & .technologies-section {
            margin: 0;
            padding: 0 0 6.5rem;

            & .technologies-section__list {
        
                &-item {
                    justify-content: start !important;
                    ${props => generateItemsStyle(props.iconsSize)}
                }
        
                @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
                    grid-template-columns: repeat(2, 1fr);
                }
        
                @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
                    column-gap: 4.375rem;
                }
            }

            @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
                padding: 0;
            }
        }

        &-web {
            & .technologies-section__list {
                column-gap: 9.375rem;
            }
        }

        &-mobile {
            & .technologies-section__list {
                column-gap: 6.5625rem;
            }
        }
    }
`;
