import React from "react";
import kotlinIcon from "@images/kotlin-logo.svg";
import flutterIcon from "@images/flutter-icon.svg";
import swiftIcon from "@images/swift-logo.svg";
import reactNativeIcon from "@images/react-native-logo.svg";
import loadable from "@loadable/component";
import { TechnologySectionStyle } from "../../../Styles/TechnologySectionStyle";

const TechnologyListSection = loadable(() =>
    import("@common/CommonSections/TechnologySection/TechnologySection")
);

const technologies = [
    {
        technologyIcon: swiftIcon,
        technologyIconAlt: "swift logo",
    },
    {
        technologyIcon: kotlinIcon,
        technologyIconAlt: "kotlin logo",
    },
    {
        technologyIcon: flutterIcon,
        technologyIconAlt: "flutter logo",
    },
    {
        technologyIcon: reactNativeIcon,
        technologyIconAlt: "react native logo",
    },
];

const iconsSize = [
    {
        desktopSize: [55, 178],
        tabletSize: [41, 132],
        mobileSize: [27, 87],
    },
    {
        desktopSize: [35, 170],
        tabletSize: [26, 126],
        mobileSize: [17, 82],
    },
    {
        desktopSize: [64, 233],
        tabletSize: [48, 175],
        mobileSize: [32, 116],
    },
    {
        desktopSize: [62, 192],
        tabletSize: [47, 145],
        mobileSize: [31, 96],
    },
];

const TechnologySection = () => (
    <>
        <TechnologySectionStyle iconsSize={iconsSize} />
        <div className="technology-section__conatiner technology-section__conatiner-mobile">
            <TechnologyListSection technologies={technologies} />
        </div>
    </>
);

export default TechnologySection;
